/* ========================================================================
* DOM-based Routing
* Based on http://goo.gl/EUTi53 by Paul Irish
*
* Only fires on body classes that match. If a body class contains a dash,
* replace the dash with an underscore when adding it to the object below.
*
* .noConflict()
* The routing is enclosed within an anonymous function so that you can
* always reference jQuery with $, even when in .noConflict() mode.
* ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var scrollToDiv, leftDesc, rightPic;
        var menu = $('.rm-nav').rMenu({

          // Optional Settings
          minWidth: '1200',
          toggleBtnBool: false
        });

        $("#menu-accordian-menu").metisMenu({
          doubleTapToGo: true
        });


        $('input, textarea').placeholder();


        // function catDescHeight(){
        //   $('.prod-arch-upper .left .white-bg').height('auto');
        //   $('.prod-arch-upper .right').height('auto');
        //   leftDesc = $('.prod-arch-upper .left .white-bg').height();
        //   rightPic = $('.prod-arch-upper .right').height();
        //   if (rightPic > leftDesc) {
        //     $('.prod-arch-upper .left .white-bg').height($('.prod-arch-upper .right').height());
        //   }
        //
        // }

        function featProductTitleAlign(){
          $(".home-featured .product .woocommerce-loop-product__title").height('auto');
          var maxExcerpt = Math.max.apply(Math, $(".home-featured .product .woocommerce-loop-product__title").map(function() { return $(this).height(); }));
          $(".home-featured .product .woocommerce-loop-product__title").each(function() {
            $(this).height(maxExcerpt);
          });
        }

        function relatedProductTitleAlign(){
          $(".related .product .woocommerce-loop-product__title").height('auto');
          var maxExcerpt = Math.max.apply(Math, $(".related .product .woocommerce-loop-product__title").map(function() { return $(this).height(); }));
          $(".related .product .woocommerce-loop-product__title").each(function() {
            $(this).height(maxExcerpt);
          });
        }

        function shopProductTitleAlign(){
          $(".shop .woocommerce .product .woocommerce-loop-product__title").height('auto');
          var maxExcerpt = Math.max.apply(Math, $(".shop .woocommerce .product .woocommerce-loop-product__title").map(function() { return $(this).height(); }));
          $(".shop .woocommerce .product .woocommerce-loop-product__title").each(function() {
            $(this).height(maxExcerpt);
          });
        }

        function pageProductTitleAlign(){
          $(".woocommerce-page .woocommerce .product .woocommerce-loop-product__title").height('auto');
          var maxExcerpt = Math.max.apply(Math, $(".woocommerce-page .woocommerce .product .woocommerce-loop-product__title").map(function() { return $(this).height(); }));
          $(".woocommerce-page .woocommerce .product .woocommerce-loop-product__title").each(function() {
            $(this).height(maxExcerpt);
          });
        }

        function featProductPAlign(){
          $(".home-featured .products .product p").height('auto');
          var maxExcerpt = Math.max.apply(Math, $(".home-featured .products .product p").map(function() { return $(this).height(); }));
          $(".home-featured .products .product p").each(function() {
            $(this).height(maxExcerpt);
          });
        }

        function relatedProductPAlign(){
          $(".related .product p").height('auto');
          var maxExcerpt = Math.max.apply(Math, $(".related .product p").map(function() { return $(this).height(); }));
          $(".related .product p").each(function() {
            $(this).height(maxExcerpt);
          });
        }

        function shopProductPAlign(){
          $(".shop .woocommerce .product p").height('auto');
          var maxExcerpt = Math.max.apply(Math, $(".shop .woocommerce .product p").map(function() { return $(this).height(); }));
          $(".shop .woocommerce .product p").each(function() {
            $(this).height(maxExcerpt);
          });
        }

        function pageProductPAlign(){
          $(".woocommerce-page .woocommerce .product p").height('auto');
          var maxExcerpt = Math.max.apply(Math, $(".woocommerce-page .woocommerce .product p").map(function() { return $(this).height(); }));
          $(".woocommerce-page .woocommerce .product p").each(function() {
            $(this).height(maxExcerpt);
          });
        }


        $(window).load(function() {
          featProductTitleAlign();
          featProductPAlign();
          relatedProductTitleAlign();
          relatedProductPAlign();
          shopProductTitleAlign();
          shopProductPAlign();
          pageProductTitleAlign();
          pageProductPAlign();

          // if ($(window).width() > 992) {
          //   //code
          //   catDescHeight();
          // }else{
          //   $('.prod-arch-upper .left .white-bg').height('auto');
          //   $('.prod-arch-upper .right').height('auto');
          // }

          // var max = Math.max.apply(Math, $(".products li").map(function() { return $(this).height(); }));
          // $(".products li").each(function() {
          //   $(this).height(max);
          // });
        });


        $('.more-info').click(function(){
          scrollToDiv = $(this).data( "go" );
          $("html, body").animate({ scrollTop: $(scrollToDiv).offset().top }, 1000);

        });

        $('.more-related').click(function(){
          scrollToDiv = $(this).data( "go" );
          $("html, body").animate({ scrollTop: $(scrollToDiv).offset().top }, 1000);

        });

        $(window).resize(function() {
          featProductTitleAlign();
          featProductPAlign();
          relatedProductTitleAlign();
          relatedProductPAlign();
          shopProductTitleAlign();
          shopProductPAlign();
          pageProductTitleAlign();
          pageProductPAlign();

          // if ($(window).width() > 992) {
          //   catDescHeight();
          // }else{
          //   $('.prod-arch-upper .left .white-bg').height('auto');
          //   $('.prod-arch-upper .right').height('auto');
          // }
        });

        $('.header-cart-container').click(function(){
          //console.log("cart click");
          $('.cart-pop').css({'display':'block'});
          $('.cart-pop').animate({
            opacity: 1
          }, 1000, function() {
            // Animation complete.
          });
        });

        $('.cart-pop-close').click(function(){
          //console.log("cart click");
          $('.cart-pop').animate({
            opacity: 0
          }, 1000, function() {
            // Animation complete.
            $('.cart-pop').css({'display':'none'});
          });
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        var body = document.body, html = document.documentElement;

        var winHeight = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );

        // function parHeight(){
        //  $('.par-one').height(winHeight);
        //  $('.par-two').height(winHeight);
        //  $('.par-three').height(winHeight);
        // }

        $(window).load(function() {
          $('.flexslider').flexslider({
            animation: "fade",
            controlNav: false,
            directionNav: false
          });

          // parHeight();
        });

        // function parallax(){
        //     var scrolled = $(window).scrollTop();
        //     $('.par-one').css('top', -(scrolled * 0.2) + 'px');
        //     $('.par-two').css('top', -(scrolled * 0.4) + 'px');
        //     $('.par-three').css('top', -(scrolled * 0.6) + 'px');
        // }

        // $(window).scroll(function(e){
        //     parallax();
        // });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
